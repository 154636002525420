.dream-toast-container {
  display: flex;
  justify-content: center;


  &.md-display {
    position: absolute;
    left: 0;
    top: 6px;
  }

  .toast.show {
    width: 100%;
    flex: 1;
    margin-bottom: 0.75rem;
  }

  &.md-display .toast.show {
    width: 400px;
    margin-left: 6px;
  }

  .close > span:not(.sr-only) {
    color: white;
  }

  .toast-body img {
    width: 50px;
    height: 50px;
  }

  .product-alert-text {
    font-size: 1rem;
  }

  .toast-header {
    img {
      background-color: white;
      width: 20px;
      height: 20px;
    }

    button {
      box-shadow: none;
    }
  }


  .disabled {
    opacity: .7;

    button {
      opacity: .7;
    }
    .dropdown-menu {
      opacity: unset;
    }
  }
}