.login-container {
  .abcRioButton {
    border-radius: .25rem;
    width: 100%;
    box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02);
    border: 0;
    transition: box-shadow .15s ease;
  }
  .abcRioButtonIcon {
    border-radius: .25rem;
  }

  .abcRioButtonContents {
    //font-family: inherit;
    font-weight: 600;
  }
}
