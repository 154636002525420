.form-dropzone {

  .dz-message {
    //height: 100%;
    display: flex;
    justify-content: flex-end;
    padding-left: 1rem;
    padding-top: 15rem;
    padding-bottom: 1rem;
    height: 100%;
    button {
      //background-color: transparent;
      height: 45px;
      width: 60px;
    }
  }

  &.dropzone-single .dz-message:hover {
    opacity: 1;
  }

  &.dropzone-single .dz-message {
    background-color: rgba(255, 255, 255, 0);
    color: white;
    opacity: 1;
  }
}